import { navigateTo, useNuxtApp } from "#imports";
import { useQueryClient } from "@tanstack/vue-query";
import { unref, type MaybeRef } from "vue";
import useNotify from "~/composables/useNotify";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { createMutation, createQuery } from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";
import { getPayload } from "./EmailService";

import type { MyQueryOptions } from "~/utils/queryUtils";
import type { BackendBookingViewModel } from "../models/Case/Booking.viewmodel";
import type { ScreenResultViewModel } from "../models/Case/Screen.model";
import type { Email } from "../models/Email/Email.model";
import type { EmailTemplate } from "../models/Email/EmailTemplate.model";
import type { RequestObject } from "../models/utils/Api.model";

const endpoints = {
  get: parseUriTemplate("/api/cm/email-templates/{id}"),
  list: "/api/cm/email-templates",
  create: "/api/cm/email-templates",
  update: parseUriTemplate("/api/cm/email-templates/{id}"),
  delete: parseUriTemplate("/api/cm/email-templates/{id}"),
  download: parseUriTemplate("/api/cm/case/${id}/emails/download"),
} as const;

const getMailList = (payload: EmailTemplate) => {
  return {
    ...payload,
    mailList: payload.mailList.map((email) => {
      if (email) return { address: email };
    }),
  };
};

export const useEmailTemplateService = () => {
  const { $api } = useNuxtApp();
  const client = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();

  const getEmailTemplate = (id: string, signal?: AbortSignal) => {
    if (id === "new") return;
    return $api(endpoints.get.expand({ id }), { signal });
  };

  const useGetEmailTemplateQuery = (id: string, options?: MyQueryOptions) =>
    createQuery(
      [QUERY_KEYS.EmailTemplates.get, id],
      ({ signal }) => getEmailTemplate(id, signal),
      options
    );

  const listEmailTemplates = (req: RequestObject, signal?: AbortSignal) =>
    $api(endpoints.list, {
      query: {
        page: req.page,
        size: req.pageSize,
        name: req.search,
      },
      signal,
    });

  const useListEmailTemplatesQuery = (
    req: MaybeRef<RequestObject> = {
      page: 1,
      pageSize: 20,
    },
    opt?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.EmailTemplates.list],
      ({ signal }) => listEmailTemplates(unref(req), signal),
      opt
    );

  const createEmailTemplate = (payload: EmailTemplate) =>
    $api(endpoints.create, {
      method: "POST",
      body: getMailList(payload),
    });

  const useCreateEmailTemplateMutation = () =>
    createMutation((template: EmailTemplate) => createEmailTemplate(template), {
      onError: () => notifyError("Error creating email template"),
      onSuccess: () => {
        client.invalidateQueries({
          queryKey: [QUERY_KEYS.EmailTemplates.list],
        });
        notifySuccess("Success", "Email template created");
        navigateTo("/email-templates");
      },
    });

  const updateEmailTemplate = (id: string, payload: EmailTemplate) =>
    $api(endpoints.update.expand({ id }), {
      method: "PUT",
      body: getMailList(payload),
    });

  const useUpdateEmailTemplateMutation = () => {
    type UpdateEmailTemplatePayload = {
      id: string;
      values: EmailTemplate;
    };
    return createMutation(
      ({ id, values }: UpdateEmailTemplatePayload) =>
        updateEmailTemplate(id, values),
      {
        onSuccess: (x, { id }) => {
          client.invalidateQueries({
            queryKey: [QUERY_KEYS.EmailTemplates.get, id],
          });
          client.invalidateQueries({
            queryKey: [QUERY_KEYS.EmailTemplates.list],
          });
          notifySuccess("Success", "Email template updated");
        },
        onError: () => {
          return notifyError("Error updating email template");
        },
      }
    );
  };

  // proxy endpoint
  const deleteEmailTemplate = (id: string) =>
    $api<undefined>(endpoints.delete.expand({ id }), {
      method: "DELETE",
    });

  const useDeleteEmailTemplateMutation = () =>
    createMutation((id: string) => deleteEmailTemplate(id), {
      onSuccess: () => {
        client.invalidateQueries({
          queryKey: [QUERY_KEYS.EmailTemplates.list],
        });
        notifySuccess("Success", "Email Template deleted");
      },
      onError: () => notifyError("Error Deleting email template"),
    });

  const exportCaseEmail = (
    id: string,
    payload: Email,
    screenResult?: ScreenResultViewModel,
    booking?: BackendBookingViewModel
  ) =>
    $api(endpoints.download.expand({ id }), {
      method: "POST",
      body: getPayload({
        recipients: payload.recipients,
        message: payload.message,
        emailTemplate: payload.emailTemplate,
        screenResult: screenResult,
        booking: booking,
      }),
    });

  const useExportCaseEmailMutation = () =>
    createMutation(
      ({
        caseId,
        payload,
        screenResult,
        booking,
      }: {
        caseId: string;
        payload: Email;
        screenResult?: ScreenResultViewModel;
        booking?: BackendBookingViewModel;
      }) => exportCaseEmail(caseId, payload, screenResult, booking),
      {
        onError: () => notifyError("Error creating email"),
        onSuccess: () => notifySuccess("Success", "Email created"),
      }
    );

  return {
    useListEmailTemplatesQuery,
    useGetEmailTemplateQuery,
    getEmailTemplate,
    createEmailTemplate,
    listEmailTemplates,
    useUpdateEmailTemplateMutation,
    useCreateEmailTemplateMutation,
    useDeleteEmailTemplateMutation,
    useExportCaseEmailMutation,
  };
};
